.banner-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: white;
  }
  
  .banner-content {
    display: inline-flex;
    position: relative;
  }
  
  .banner-tile {
    flex: 0 0 auto;
    width: 100px;
    padding: 10px;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
  }
  
  .tile-name {
    font-size: 12px;
    color: #4f4f4f;
  }
  
  .tile-amount {
    font-size: 15px;
    margin: 5px 0;
    font-weight: bold;
    color: black;
  }
  
  .tile-change {
    font-size: 14px;
  }
  
  .tile-change.positive {
    color: #22c55e;  /* green */
  }
  
  .tile-change.negative {
    color: #ef4444;  /* red */
  }
  
  .tile-change.neutral {
    color: #6b7280;  /* gray */
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100px * var(--item-count)));
    }
  }