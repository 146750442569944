[data-theme="high-contrast"] .form-control {
  border-color: var(--white-color);
  color: var(--white-color);
}
[data-theme="high-contrast"] .list-group-flush .text-light {
  color: var(--dark-color) !important;
}
body a {
  color: var(--color-500);
}
body .form-floating .form-control,
body .form-floating .form-select {
  font-size: unset;
  height: unset;
  line-height: unset;
}
body .navbar-toggler-icon {
  height: auto;
}
.navbar .nav-link {
  color: var(--color-500);
  font-size: 16px;
}
@media only screen and (max-width: 1199px) {
  .navbar .navbar-collapse {
    background-color: var(--card-color);
    margin-top: 5px;
    padding: 15px;
    border-radius: 5px;
  }
}
.navbar .navbar-toggler-icon i {
  color: var(--text-color);
}
.play-btn {
  position: relative;
  overflow: hidden;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background-color: var(--secondary-color);
}
.play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--white-color);
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.play-btn:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  top: -15%;
  left: -15%;
}
.play-btn:hover:after {
  border-left: 15px solid var(--primary-color);
  transform: scale(20);
}
.play-btn:hover:before {
  content: "";
  position: absolute;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--white-color);
  -webkit-animation: none;
  animation: none;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-radius: 0;
  z-index: 200;
}
.page-setting {
  position: fixed;
  right: 0;
  top: 150px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--border-color);
  text-align: center;
  line-height: 30px;
  z-index: 999;
}
.letter-spacing {
  letter-spacing: 2.5px;
}
.support img {
  height: 40px;
}
.top-date {
  color: var(--card-color);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em 1em;
  opacity: 0.7;
}
.top-date .one span {
  font-weight: bold;
  font-size: 40px;
}
@media (max-width: 600px) {
  .top-date .one span {
    font-size: 35px;
  }
}
.top-date .two span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}
@media (max-width: 600px) {
  .top-date .two span {
    font-size: 13px;
    line-height: 13px;
  }
}
.section {
  padding: 80px 0px;
}
.section.market-update .price-chart {
  border: 1px solid var(--color-500);
}
.section.footer .footer-bottom {
  border-top: 1px solid var(--color-400);
}
@media only screen and (max-width: 1024px) {
  .section {
    padding: 40px 0px;
  }
}
.page-three {
  --primary-rgb: 84, 22, 144;
  --primary-color: #002041;
  --accent-color: #ff8d29;
  --secondary-color: #1d1e32;
  --theme-color1: #320071;
  --theme-color2: #7d3eb9;
  --theme-color3: #a765e3;
  --theme-color4: #d38cff;
  --theme-color5: #96b0b7;
  --theme-color6: #2f4858;
  --primary-gradient: linear-gradient(
    -45deg,
    var(--primary-color),
    var(--secondary-color)
  );
}
.page-three .bg-gradient {
  background: var(--primary-gradient);
}
.page-three .fill-white {
  fill: var(--white-color) !important;
}
.page-three a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}
.page-three a:focus {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three button:focus {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three .btn:focus {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three .btn:not(:disabled):not(.disabled).active {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three .btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three .btn.focus {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.page-three span {
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}
.page-three audio {
  vertical-align: middle;
}
.page-three canvas {
  vertical-align: middle;
}
.page-three iframe {
  vertical-align: middle;
}
.page-three img {
  vertical-align: middle;
  max-width: 100%;
}
.page-three svg {
  vertical-align: middle;
}
.page-three video {
  vertical-align: middle;
}
.page-three h1 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 50px;
}
.page-three h1 a {
  color: inherit;
}
.page-three h2 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 40px;
}
.page-three h2 a {
  color: inherit;
}
.page-three h3 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 30px;
}
.page-three h3 a {
  color: inherit;
}
.page-three h4 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 25px;
}
.page-three h4 a {
  color: inherit;
}
.page-three h5 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 20px;
}
.page-three h5 a {
  color: inherit;
}
.page-three h6 {
  font-weight: 600;
  margin: 0px;
  color: var(--primary-color);
  font-family: "Spartan", sans-serif;
  font-size: 16px;
}
.page-three h6 a {
  color: inherit;
}
.page-three .img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.page-three .section {
  padding-top: 110px;
  padding-bottom: 110px;
  position: relative;
}
.page-three .section-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 300px;
  position: relative;
  z-index: 5;
  color: black;
}
.page-three .section-title h3 {
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
  color: var(--primary-color);
  text-transform: capitalize;
}
.page-three .section-title h2 {
  font-size: 34px;
  margin-bottom: 20px;
  line-height: 42px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}
.page-three .section-title p {
  font-size: 16px;
  line-height: 28px;
}
.page-three .section-title.align-right {
  padding: 0;
  padding-left: 600px;
}
.page-three .section-title.align-right h2:before {
  display: none;
}
.page-three .section-title.align-right h2:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: var(--primary-color);
  content: "";
}
.page-three .section-title.align-left {
  padding: 0;
  padding-right: 600px;
}
.page-three .section-title.align-left h2:before {
  left: 0;
  margin-left: 0;
}
.page-three .scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: var(--primary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: var(--white-color) !important;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 5px;
}
.page-three .scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: var(--primary-color);
}
.page-three .overlay {
  position: relative;
  z-index: 1;
}
.page-three .overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: var(--primary-color);
  content: "";
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: -1;
}
.page-three .button {
  margin-left: 0 !important;
}
.page-three .button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  padding: 14px 30px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  margin-right: 7px;
  overflow: hidden;
}
.page-three .button .btn i {
  display: inline-block;
  margin-right: 5px;
}
.page-three .button .btn:last-child {
  margin: 0;
}
.page-three .button .btn:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}
.page-three .button .btn-alt {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.page-three .button .btn-alt:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.page-three .align-left {
  text-align: left;
}
.page-three .align-right {
  text-align: right;
}
.page-three .align-center {
  text-align: center;
}
.page-three .header {
  width: 100%;
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.page-three .header .navbar-brand img {
  width: 200px;
}
@media (min-width: 991px) {
  .page-three .header .navbar .navbar-nav {
    align-items: center;
  }
}
.page-three .header .navbar .navbar-nav .nav-item a.active {
  color: var(--white-color);
}
.page-three .header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: var(--white-color);
}
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .page-three .header .navbar .navbar-collapse {
    background-color: var(--primary-color);
  }
}
.page-three .header .button {
  display: inline-block;
  margin-left: 10px;
}
.page-three .header .button .btn {
  background-color: var(--white-color);
  color: var(--primary-color);
  font-weight: 500;
}
.page-three .header .button .btn:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}
.page-three .header.sticky .button .btn {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .header.sticky .button .btn:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .sticky {
  position: fixed;
  z-index: 99;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  top: 0;
  background-color: var(--white-color);
}
.page-three .sticky .navbar .navbar-nav .nav-item a.active {
  color: var(--primary-color);
}
.page-three .sticky .navbar .navbar-nav .nav-item a {
  color: var(--primary-color);
}
.page-three .sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: var(--white-color);
}
.page-three .sticky .navbar .navbar-nav .nav-item a.btn {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.page-three .sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}
.page-three .sticky .navbar .navbar-brand {
  color: var(--primary-color);
}
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .page-three .sticky .navbar .navbar-collapse {
    background-color: var(--white-color);
  }
}
.page-three .sticky .navbar-nav .nav-item:hover a {
  color: var(--theme-color5);
}
.page-three .sticky .button .btn {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .sticky .button .btn:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .navbar-area {
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0;
}
.page-three .navbar-area.sticky {
  position: fixed;
  z-index: 99;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0px 0;
}
.page-three .navbar {
  padding: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-three .navbar-brand {
  padding-left: 0;
  border-radius: 0;
  color: var(--white-color);
}
.page-three .mobile-menu-btn {
  padding: 0px;
}
.page-three .mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.page-three .mobile-menu-btn .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #222;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-three .mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}
.page-three .mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}
.page-three .mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}
.page-three .navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-right: 40px;
}
.page-three .navbar-nav .nav-item:last-child {
  margin-right: 0 !important;
}
.page-three .navbar-nav .nav-item:hover a {
  color: var(--theme-color4);
}
.page-three .navbar-nav .nav-item:hover a:before {
  width: 100%;
}
.page-three .navbar-nav .nav-item:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.page-three .navbar-nav .nav-item:hover > .sub-menu .sub-menu {
  left: 100%;
  top: 0;
}
.page-three .navbar-nav .nav-item:hover .sub-menu {
  top: 100% !important;
}
.page-three .navbar-nav .nav-item a {
  font-size: 15px;
  color: var(--white-color);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  padding: 35px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
  position: relative;
  text-transform: capitalize;
}
.page-three .navbar-nav .nav-item a::after {
  opacity: 0;
  visibility: hidden;
}
.page-three .navbar-nav .nav-item a.active {
  color: var(--white-color);
}
.page-three .navbar-nav .nav-item a.dd-menu::after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
}
.page-three .navbar-nav .nav-item .sub-menu {
  padding: 30px;
  min-width: 240px;
  background: var(--white-color);
  -webkit-box-shadow: 0px 5px 20px #0000001a;
  box-shadow: 0px 5px 20px #0000001a;
  position: absolute;
  top: 110% !important;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0 0 4px 4px;
  border-radius: 5px;
}
.page-three .navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%;
  margin-bottom: 15px;
}
.page-three .navbar-nav .nav-item .sub-menu .nav-item:last-child {
  margin: 0;
}
.page-three .navbar-nav .nav-item .sub-menu .nav-item a {
  padding: 0;
  color: #888;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.page-three .navbar-nav .nav-item .sub-menu .nav-item a:hover {
  color: var(--primary-color);
}
.page-three .navbar-nav .nav-item .sub-menu > li {
  display: block;
  margin-left: 0;
}
.page-three .navbar-nav .nav-item .sub-menu > li:last-child {
  border: none;
}
.page-three .navbar-nav .nav-item .sub-menu > li > a {
  font-weight: 400;
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}
.page-three .navbar-nav .nav-item .sub-menu > li:first-child a {
  border: none;
}
.page-three .navbar-nav .nav-item .sub-menu > li.active > a {
  color: var(--primary-color) !important;
}
.page-three .navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%;
}
.page-three .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block;
}
.page-three .hero-area {
  position: relative;
  background-color: var(--primary-color);
  padding: 180px 0 180px 0;
  z-index: 0;
}
.page-three .hero-area .hero-shape {
  position: absolute;
  left: 0;
  bottom: -1px;
  z-index: -1;
}
.page-three .hero-area .hero-content {
  border-radius: 0;
  position: relative;
  z-index: 1;
  text-align: left;
}
.page-three .hero-area .hero-content h4 {
  color: var(--white-color);
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
}
.page-three .hero-area .hero-content h1 {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: var(--white-color);
  text-transform: capitalize;
}
.page-three .hero-area .hero-content h1 span {
  position: relative;
  z-index: 0;
}
.page-three .hero-area .hero-content h1 span .text-shape {
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  z-index: -1;
}
.page-three .hero-area .hero-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--white-color);
  margin-top: 20px;
}
.page-three .hero-area .hero-content .button {
  margin-top: 50px;
}
.page-three .hero-area .hero-content .button .btn:hover {
  color: var(--primary-color);
  background-color: var(--white-color);
}
.page-three .hero-area .hero-image {
  text-align: center;
  position: relative;
  z-index: 0;
}
.page-three .hero-area .hero-image .main-image {
  z-index: 0;
}
.page-three .hero-area .hero-image .h2-move-1 {
  position: absolute;
  left: 42%;
  -webkit-animation: bounce 3s linear infinite;
  animation: bounce 3s linear infinite;
}
.page-three .hero-area .hero-image .h2-move-2 {
  position: absolute;
  left: 45%;
  top: 68%;
  -webkit-animation: bounce 3s linear infinite;
  animation: bounce 3s linear infinite;
}
.page-three .hero-area .hero-image .h2-move-3 {
  position: absolute;
  left: 47%;
  top: 26%;
  z-index: -1;
  -webkit-animation: bounce 4s linear infinite;
  animation: bounce 4s linear infinite;
}
.page-three .hero-area .hero-image .dot-dot {
  position: absolute;
  left: -10%;
  top: -14%;
  height: 113%;
  -webkit-animation: rotate2d 30s linear infinite;
  animation: rotate2d 30s linear infinite;
  width: 100%;
}
.page-three .fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.page-three .fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.page-three .fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.page-three .fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.page-three .spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}
.page-three .bounce {
  -webkit-animation: bounce 2s linear infinite;
  animation: bounce 1s linear infinite;
}
.page-three .rotate2d {
  -webkit-animation: rotate2d 2s linear infinite;
  animation: rotate2d 1s linear infinite;
}
.page-three .rotate-2d {
  -webkit-animation: rotate-2d 2s linear infinite;
  animation: rotate-2d 1s linear infinite;
}
.page-three .feature {
  position: relative;
  z-index: 0;
  padding-top: 80px;
}
.page-three .feature .section-title {
  margin-bottom: 50px;
}
.page-three .feature .upper-content .lasthead {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
}
.page-three .feature .upper-content .title {
  font-size: 28px;
  font-weight: 600;
  font-size: 40px;
  font-weight: 700;
  padding: 21px 0 16px;
}
.page-three .feature .upper-content p {
  margin-top: 20px;
}
.page-three .feature .upper-content .button {
  margin-top: 40px;
}
.page-three .feature .feature-box {
  min-height: 300px;
  margin-top: 30px;
  border-radius: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 30px rgba(81, 94, 125, 0.082);
  box-shadow: 0px 0px 30px rgba(81, 94, 125, 0.082);
  padding: 40px 50px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-top: 3px solid #f4f7fa;
  border-bottom: 3px solid #f4f7fa;
}
.page-three .feature .feature-box:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.103);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.103);
}
.page-three .feature .feature-box .tumb img {
  height: 150px;
}
.page-three .feature .feature-box .text-title {
  color: #002041;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px;
}
.page-three .feature .feature-box p {
  line-height: 26px;
  font-size: 15px;
}
.page-three .start-process {
  background-color: #78a7e0;
}
.page-three .start-process .section-title {
  margin-bottom: 50px;
}
.page-three .start-process .single-process {
  position: relative;
  padding-left: 100px;
  margin-top: 40px;
  color: black;
}
.page-three .start-process .single-process .serial {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.089);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.089);
  position: absolute;
  left: 0;
  color: var(--primary-color);
  top: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .start-process .single-process:hover .serial {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .start-process .single-process h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}
.page-three .start-process.dark {
  background-color: var(--primary-color);
}
.page-three .start-process.dark .section-title h3 {
  color: var(--white-color);
}
.page-three .start-process.dark .section-title h2 {
  color: var(--white-color);
}
.page-three .start-process.dark .section-title p {
  color: var(--white-color);
}
.page-three .start-process.dark .single-process h3 {
  color: var(--white-color);
}
.page-three .start-process.dark .single-process p {
  color: #dadada;
}
.page-three .intro-video-area {
  background: var(--primary-gradient);
  position: relative;
  z-index: 9;
}
.page-three .intro-video-area .section-title {
  margin-bottom: 150px;
  padding: 0 265px;
}
.page-three .intro-video-area .section-title span {
  display: block;
  margin-bottom: 20px;
  color: var(--primary-color);
}
.page-three .intro-video-area .inner-content-head {
  padding: 30px;
  border: 1px solid #eeeeee40;
  border-radius: 30px;
  background: #ffffff24;
}
.page-three .intro-video-area .inner-content {
  background-color: var(--white-color);
  padding: 200px 0;
  height: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.09);
  position: relative;
  overflow: hidden;
}
.page-three .intro-video-area .inner-content .shape1 {
  height: 120px;
  width: 120px;
  position: absolute;
  right: 0px;
  bottom: 58px;
}
.page-three .intro-video-area .inner-content .shape2 {
  height: 100px;
  width: 100px;
  position: absolute;
  left: -10px;
  top: 30px;
}
.page-three .intro-video-area .intro-video-play {
  position: relative;
  z-index: 9;
}
.page-three .intro-video-area .intro-video-play .play-thumb a {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 83px;
  background: var(--primary-color);
  border-radius: 50%;
  padding-left: 5px;
  font-size: 22px;
  color: var(--white-color);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.page-three .intro-video-area .intro-video-play .play-thumb a:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.page-three .road-map {
  background: var(--primary-gradient);
  position: relative;
  padding-bottom: 200px;
}
.page-three .road-map .road-map-slider {
  margin: 0;
  padding: 10px 0;
}
.page-three .road-map .tns-nav {
  text-align: center;
  position: absolute;
  bottom: -100px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  left: 50%;
  z-index: 9;
  margin: 0;
}
.page-three .road-map .tns-nav button {
  height: 6px;
  width: 14px;
  background-color: var(--white-color);
  border-radius: 5px;
  display: inline-block;
  border: none;
  margin: 0px 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .road-map .tns-nav button:hover {
  background-color: var(--primary-color);
}
.page-three .road-map .tns-nav button.tns-nav-active {
  width: 25px;
  background-color: var(--primary-color);
}
.page-three .road-map .top-row {
  position: relative;
}
.page-three .road-map .top-row::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100%;
  margin-top: -1px;
  height: 2px;
  background: var(--primary-color);
}
.page-three .road-map .single-map {
  border-radius: 5px;
  background-color: var(--white-color);
  padding: 25px 20px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .road-map .single-map:hover {
  background-color: var(--primary-color);
}
.page-three .road-map .single-map:hover .arrow {
  background-color: var(--primary-color);
}
.page-three .road-map .single-map:hover h3 {
  color: var(--white-color);
}
.page-three .road-map .single-map:hover h3 span {
  color: var(--white-color);
}
.page-three .road-map .single-map::before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -49px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 2px solid var(--primary-color);
  margin-left: -9px;
}
.page-three .road-map .single-map .arrow {
  height: 16px;
  width: 16px;
  background-color: var(--white-color);
  display: block;
  position: absolute;
  left: 50%;
  bottom: -8px;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg);
  margin-left: -8px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .road-map .single-map h3 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .road-map .single-map h3 span {
  color: #727272;
  font-size: 13px;
  display: block;
  margin-top: 10px;
  line-height: 22px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .road-map .single-map.down {
  margin-top: 200px;
}
.page-three .road-map .single-map.down::before {
  bottom: auto;
  top: -49px;
}
.page-three .road-map .single-map.down .arrow {
  bottom: auto;
  top: -8px;
}
.page-three .testimonials {
  background-color: #f4f7fa;
  position: relative;
  padding-bottom: 180px;
}
.page-three .testimonials .testimonial-slider {
  margin: 0;
}
.page-three .testimonials .tns-nav {
  text-align: center;
  position: absolute;
  bottom: 90px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  left: 50%;
  z-index: 9;
  margin: 0;
}
.page-three .testimonials .tns-nav button {
  height: 6px;
  width: 14px;
  background-color: var(--primary-color);
  border-radius: 5px;
  display: inline-block;
  border: none;
  margin: 0px 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .testimonials .tns-nav button:hover {
  background-color: var(--primary-color);
}
.page-three .testimonials .tns-nav button.tns-nav-active {
  width: 25px;
  background-color: var(--primary-color);
}
.page-three .testimonials .single-testimonial {
  background-color: var(--white-color);
  padding: 50px 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin: 15px 0;
}
.page-three .testimonials .single-testimonial:hover {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.103);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.103);
}
.page-three .testimonials .single-testimonial::before {
  position: absolute;
  content: "";
  left: -30px;
  top: -30px;
  height: 60px;
  width: 60px;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.page-three .testimonials .single-testimonial .text h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.page-three .testimonials .single-testimonial .author {
  position: relative;
  padding-left: 70px;
  margin-top: 40px;
}
.page-three .testimonials .single-testimonial .author img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.page-three .testimonials .single-testimonial .author h4 {
  font-size: 15px;
  font-weight: 600;
}
.page-three .testimonials .single-testimonial .author h4 span {
  color: #727272;
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
}
.page-three .testimonials .single-testimonial .quote-icon i {
  font-size: 45px;
  color: var(--primary-color);
  position: absolute;
  opacity: 0.1;
  right: 40px;
  bottom: 50px;
}
.page-three .single-blog-grid {
  margin-top: 30px;
  background-color: var(--white-color);
  border-radius: 8px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.089);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.089);
  overflow: hidden;
}
.page-three .single-blog-grid .blog-img {
  overflow: hidden;
}
.page-three .single-blog-grid .blog-img a {
  width: 100%;
}
.page-three .single-blog-grid .blog-img img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-three .single-blog-grid:hover .blog-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.page-three .single-blog-grid .blog-content {
  padding: 35px;
}
.page-three .single-blog-grid .blog-content .meta-info {
  margin-bottom: 20px;
}
.page-three .single-blog-grid .blog-content .meta-info a {
  display: inline-block;
  margin-right: 22px;
  font-size: 14px;
  color: #727272;
  font-weight: 500;
}
.page-three .single-blog-grid .blog-content .meta-info a i {
  display: inline-block;
  margin-right: 5px;
  color: var(--primary-color);
  font-size: 16px;
}
.page-three .single-blog-grid .blog-content .meta-info a:last-child {
  margin: 0;
}
.page-three .single-blog-grid .blog-content .meta-info a:hover {
  color: var(--primary-color);
}
.page-three .single-blog-grid .blog-content h4 {
  display: block;
  line-height: 28px;
}
.page-three .single-blog-grid .blog-content h4 a {
  font-size: 17px;
  color: #002041;
  font-weight: 700;
  display: inline-block;
}
.page-three .single-blog-grid .blog-content h4 a:hover {
  color: var(--primary-color);
}
.page-three .single-blog-grid .blog-content p {
  display: block;
  margin-top: 20px;
}
.page-three .single-blog-grid .blog-content .button {
  margin-top: 20px;
}
.page-three .single-blog-grid .blog-content .button .btn {
  padding: 0;
  background-color: transparent;
  color: #24126a;
  text-decoration: underline;
  overflow: visible;
}
.page-three .single-blog-grid .blog-content .button .btn:hover {
  color: var(--primary-color);
}
.page-three .faq {
  background-color: #78a7e0;
}
.page-three .faq .section-title {
  margin-bottom: 60px;
}
.page-three .accordion-item {
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 20px #00000007;
  box-shadow: 0px 0px 20px #00000007;
  border-radius: 4px;
}
.page-three .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-right: 40px;
}
.page-three .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.page-three .accordion-item .accordion-button {
  border-radius: 4px;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  display: block;
  overflow: hidden;
  border: none;
  padding: 15px 25px;
  padding-right: 40px;
  background-color: var(--white-color);
}
.page-three .accordion-item .accordion-button .title {
  font-size: 16px;
  position: relative;
  font-weight: 600;
  float: left;
  line-height: 25px;
}
.page-three .accordion-item .accordion-button i {
  font-size: 15px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  top: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}
.page-three .accordion-item:last-child {
  margin: 0;
}
.page-three .accordion-button:not(.collapsed) {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 4px 4px 0 0;
}
.page-three .accordion-button:not(.collapsed) i::before {
  content: "\f068";
  font-family: FontAwesome;
}
.page-three .accordion-button::after {
  display: none;
}
.page-three .accordion-collapse {
  border: none;
}
.page-three .accordion-body {
  border-radius: 0 0 4px 4px;
  padding: 25px;
  background-color: var(--white-color);
}
.page-three .accordion-body p {
  margin: 0;
  margin-bottom: 20px;
  color: #777;
}
.page-three .accordion-body p:last-child {
  margin: 0;
}
.page-three .footer {
  background-color: var(--primary-color);
  position: relative;
  padding-bottom: 0;
}
.page-three .footer .footer-inner.f-about {
  padding-right: 30px;
}
.page-three .footer .footer-inner.f-about .logo {
  margin-bottom: 20px;
}
.page-three .footer .footer-inner.f-about .logo img {
  width: 200px;
}
.page-three .footer .footer-inner.f-about p {
  color: var(--white-color);
  margin-top: 20px;
  font-size: 15px;
  margin-bottom: 25px;
}
.page-three .footer .footer-inner.f-about .social-title {
  color: var(--white-color);
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}
.page-three .footer .footer-inner.f-about .social li {
  display: inline-block;
  margin-right: 15px;
}
.page-three .footer .footer-inner.f-about .social li:last-child {
  margin: 0;
}
.page-three .footer .footer-inner.f-about .social li a {
  color: var(--white-color);
}
.page-three .footer .footer-inner.f-about .social li a:hover {
  color: var(--theme-color4);
}
.page-three .footer .footer-inner.f-about .copyright-text {
  color: var(--white-color);
  font-size: 15px;
  margin-top: 40px;
}
.page-three .footer .footer-inner.f-about .copyright-text span {
  display: block;
}
.page-three .footer .footer-inner.f-about .copyright-text a {
  color: var(--white-color);
  text-decoration: underline;
}
.page-three .footer .footer-inner.f-link li {
  display: block;
  margin-bottom: 15px;
}
.page-three .footer .footer-inner.f-link li:last-child {
  margin: 0;
}
.page-three .footer .footer-inner.f-link li a {
  font-size: 15px;
  font-weight: 400;
  color: var(--white-color);
}
.page-three .footer .footer-inner.f-link li a:hover {
  color: var(--theme-color4);
}
.page-three .footer .footer-inner.newsletter p {
  color: var(--white-color);
}
.page-three .footer .footer-inner h3 {
  font-size: 17px;
  font-weight: 600;
  display: block;
  margin-bottom: 35px;
  color: var(--white-color);
}
.page-three .footer .newsletter-form {
  margin-top: 30px;
  position: relative;
}
.page-three .footer .newsletter-form input {
  height: 52px;
  width: 100%;
  border-radius: 8px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  padding-left: 18px;
  padding-right: 70px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #ffffff1f;
  color: var(--white-color);
}
.page-three .footer .newsletter-form input:hover {
  border-color: var(--primary-color);
}
.page-three .footer .newsletter-form input:focus {
  border-color: var(--primary-color);
}
.page-three .footer .newsletter-form .button {
  position: absolute;
  right: 0px;
  top: 0;
}
.page-three .footer .newsletter-form .button .sub-btn {
  height: 52px;
  width: 52px;
  border-radius: 0 6px 6px 0;
  background-color: #ffffff29;
  color: var(--white-color);
  text-align: center;
  line-height: 52px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  font-size: 18px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page-three .footer .newsletter-form .button .sub-btn:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}
.page-three .footer .copyright-area .inner-content {
  border-top: 1px solid rgba(238, 238, 238, 0.288);
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 80px;
}
@media (max-width: 767px) {
  .page-three .footer .copyright-area .copyright-text {
    text-align: center;
  }
}
.page-three .footer .copyright-area .copyright-owner {
  text-align: right;
}
@media (max-width: 767px) {
  .page-three .footer .copyright-area .copyright-owner {
    text-align: center;
  }
}
.page-three .footer .copyright-area p {
  color: var(--white-color);
  font-size: 15px;
}
.page-three .footer .copyright-area p a {
  text-decoration: underline;
  color: var(--white-color);
}
.page-three .footer .copyright-area p a:hover {
  color: var(--primary-color);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .page-three .container {
    width: 450px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-three .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .page-three .section-title {
    padding: 0px 20px;
    margin-bottom: 50px;
  }
  .page-three .section-title h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .page-three .section-title h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .page-three .section-title p {
    font-size: 15px;
  }
  .page-three .button .btn {
    padding: 14px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  .page-three .button .btn-alt {
    padding: 14px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  .page-three .header {
    padding: 18px 0 !important;
  }
  .page-three .header .mobile-menu-btn .toggler-icon {
    background-color: var(--white-color);
  }
  .page-three .header .button {
    margin: 0 !important;
  }
  .page-three .header .button .signin {
    color: var(--primary-color);
  }
  .page-three .header .button .signin:hover {
    color: var(--primary-color);
  }
  .page-three .header .button .signup {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  .page-three .header .button .signup:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  .page-three .header .navbar-collapse {
    position: absolute;
    top: 67px !important;
    left: 0;
    width: 100%;
    background-color: var(--white-color);
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 8px;
  }
  .page-three .header .navbar .navbar-nav .nav-item a:hover {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar .navbar-nav .nav-item a.active {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item {
    margin: 0;
  }
  .page-three .header .navbar-nav .nav-item:hover a {
    color: var(--primary-color);
  }
  .page-three .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
  }
  .page-three .header .navbar-nav .nav-item a::before {
    display: none;
  }
  .page-three .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }
  .page-three .header .navbar-nav .nav-item a:hover {
    color: var(--primary-color);
  }
  .page-three .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
    padding: 10px 12px !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu li.active {
    background: var(--white-color) !important;
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu li:hover {
    background: var(--white-color) !important;
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-brand img {
    width: 190px;
  }
  .page-three .navbar-nav .nav-item a.dd-menu::after {
    top: 18px;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .page-three .navbar-nav .nav-item a.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-three .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
  .page-three .hero-area {
    padding: 130px 0 150px 0;
  }
  .page-three .hero-area .hero-content {
    padding: 0px 140px;
    text-align: center;
  }
  .page-three .hero-area .hero-content .button {
    margin-top: 30px !important;
    width: 100%;
  }
  .page-three .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .page-three .hero-area .hero-content p {
    font-size: 15px;
  }
  .page-three .hero-area .hero-image {
    display: none;
  }
  .page-three .feature {
    padding-top: 50px;
  }
  .page-three .feature .section-title {
    margin-bottom: 30px;
  }
  .page-three .start-process .section-title {
    margin-bottom: 30px;
  }
  .page-three .start-process .single-process {
    padding: 0;
    text-align: center;
    margin-top: 30px;
  }
  .page-three .start-process .single-process .serial {
    position: relative;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
  }
  .page-three .intro-video-area {
    padding-top: 90px;
  }
  .page-three .intro-video-area .section-title {
    padding: 0 50px;
    margin-bottom: 120px;
  }
  .page-three .intro-video-area .inner-content {
    padding: 150px 0;
    padding-bottom: 80px;
  }
  .page-three .intro-video-area .intro-video-play .play-thumb a {
    height: 70px;
    width: 70px;
    font-size: 20px;
    line-height: 70px;
  }
  .page-three .road-map {
    padding-bottom: 120px;
  }
  .page-three .road-map .tns-nav {
    bottom: -60px;
  }
  .page-three .testimonials {
    padding-bottom: 120px;
  }
  .page-three .testimonials .tns-nav {
    bottom: 55px;
  }
  .page-three .testimonials .single-testimonial .text h4 {
    bottom: 55px;
  }
  .page-three .team .section-title {
    margin-bottom: 30px;
  }
  .page-three .faq .section-title {
    margin-bottom: 40px;
  }
  .page-three .accordion-item .accordion-button {
    padding: 18px 20px;
    padding-right: 40px;
  }
  .page-three .accordion-item .accordion-button .title {
    font-size: 14px;
  }
  .page-three .accordion-body {
    padding: 30px;
  }
  .page-three .footer {
    padding-top: 30px;
  }
  .page-three .footer .footer-inner {
    margin-top: 40px;
  }
  .page-three .footer .footer-inner h3 {
    margin-bottom: 25px;
  }
  .page-three .footer .footer-inner.f-about .logo img {
    width: 190px;
  }
}
@media (max-width: 767px) {
  .page-three .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-three .section-title {
    padding: 0px 10px;
    margin-bottom: 40px;
  }
  .page-three .section-title h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .hero-image {
    display: none;
  }
  .page-three .section-title h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 18px;
  }
  .page-three .section-title p {
    font-size: 15px;
  }
  .page-three .button .btn {
    padding: 14px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  .page-three .button .btn-alt {
    padding: 14px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  .page-three .header {
    padding: 18px 0 !important;
  }
  .page-three .header .mobile-menu-btn .toggler-icon {
    background-color: var(--white-color);
  }
  .page-three .header .navbar-collapse {
    position: absolute;
    top: 62px !important;
    left: 0;
    width: 100%;
    background-color: var(--white-color);
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 8px;
  }
  .page-three .header .navbar .navbar-nav .nav-item a:hover {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar .navbar-nav .nav-item a.active {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item {
    margin: 0;
  }
  .page-three .header .navbar-nav .nav-item:hover a {
    color: var(--primary-color);
  }
  .page-three .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
  }
  .page-three .header .navbar-nav .nav-item a::before {
    display: none;
  }
  .page-three .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }
  .page-three .header .navbar-nav .nav-item a:hover {
    color: var(--primary-color);
  }
  .page-three .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
    padding: 10px 12px !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu li.active {
    background: var(--white-color) !important;
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-nav .nav-item .sub-menu li:hover {
    background: var(--white-color) !important;
    color: var(--primary-color) !important;
  }
  .page-three .header .navbar-brand img {
    width: 180px;
  }
  .page-three .header .button {
    display: none;
  }
  .page-three .header .button .signin {
    color: var(--primary-color);
  }
  .page-three .header .button .signin:hover {
    color: var(--primary-color);
  }
  .page-three .header .button .signup {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  .page-three .header .button .signup:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  .page-three .navbar-nav .nav-item a.dd-menu::after {
    top: 18px;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .page-three .navbar-nav .nav-item a.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .page-three .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
  .page-three .hero-area {
    padding: 110px 0 100px 0;
  }
  .page-three .hero-area .hero-content {
    padding: 0 10px;
    text-align: center;
  }
  .page-three .hero-area .hero-content .button {
    margin-top: 30px;
    width: 100%;
  }
  .page-three .hero-area .hero-content .button .btn {
    width: 100%;
    width: 60%;
    margin: 0;
    margin-bottom: 7px;
  }
  .page-three .hero-area .hero-content .button .btn:last-child {
    margin: 0;
  }
  .page-three .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .page-three .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 15px;
    line-height: 26px;
  }
  .page-three .hero-area .hero-image {
    display: none;
  }
  .page-three .feature {
    padding-top: 40px;
  }
  .page-three .feature .section-title {
    margin-bottom: 30px;
  }
  .page-three .start-process .section-title {
    margin-bottom: 20px;
  }
  .page-three .start-process .single-process {
    padding: 0;
    text-align: center;
    margin-top: 30px;
  }
  .page-three .start-process .single-process .serial {
    position: relative;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
  }
  .page-three .intro-video-area {
    padding-top: 60px;
  }
  .page-three .intro-video-area .section-title {
    padding: 0 30px;
    margin-bottom: 100px;
  }
  .page-three .intro-video-area .section-title h2 {
    font-size: 20px;
  }
  .page-three .intro-video-area .section-title span {
    margin-bottom: 10px;
  }
  .page-three .intro-video-area .inner-content-head {
    padding: 0;
    border: none;
  }
  .page-three .intro-video-area .inner-content .shape1 {
    width: 80px;
    height: 80px;
    right: -50px;
    bottom: 20px;
  }
  .page-three .intro-video-area .inner-content .shape2 {
    width: 80px;
    height: 80px;
  }
  .page-three .intro-video-area .intro-video-play .play-thumb a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 18px;
  }
  .page-three .road-map {
    padding-bottom: 100px;
  }
  .page-three .road-map .tns-nav {
    bottom: -50px;
  }
  .page-three .testimonials {
    padding-bottom: 100px;
  }
  .page-three .testimonials .tns-nav {
    bottom: 50px;
  }
  .page-three .testimonials .single-testimonial {
    padding: 50px 40px;
  }
  .page-three .testimonials .single-testimonial .text h4 {
    font-size: 15px;
    line-height: 28px;
  }
}
.page-three .team .section-title {
  margin-bottom: 30px;
}
.page-three .team .img-effect2 {
  overflow: hidden; /* Keep this */
  border-radius: 8px !important; 
}
.page-three .team .img-effect2 .team-block {
  position: relative;
  width: 350px;
  height: auto;
  overflow: visible;
  border-radius: 8px !important;
}
.page-three .team .img-effect2 .team-block .team-img {
  position: relative;
  z-index: 2;
  width: 350px;
  height: auto;
  border-radius: 8px !important;
}
.page-three .team .img-effect2 .team-block .team-content {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 350px;
  padding: 25px 20px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease;
  border-radius: 8px !important;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1;
}

.page-three .team .img-effect2 .team-block:hover .team-content {
  bottom: 0px; /* Changed from -20px to 0px */
}
.page-three .team .img-effect2 .team-block .team-caption {
  color: var(--color-500);
  opacity: 1;
  transition: none;
}
.page-three .team .img-effect2:hover .team-img {
  transform: none;
  filter: none;
}
.page-three .team .img-effect2:hover .team-content {
  flex-grow: 0;
}
.page-three .faq .section-title {
  margin-bottom: 30px;
}
.page-three .accordion-item .accordion-button {
  padding: 15px 20px;
  padding-right: 40px;
}
.page-three .accordion-item .accordion-button .title {
  font-size: 14px;
}
.page-three .accordion-body {
  padding: 20px;
}
.page-three .footer {
  padding-top: 20px;
}
.page-three .footer .footer-inner {
  margin-top: 40px;
}
.page-three .footer .footer-inner h3 {
  margin-bottom: 25px;
}
.page-three .footer .footer-inner.f-about {
  padding: 0;
}
.page-three .footer .footer-inner.f-about .logo img {
  width: 180px;
}
.page-three .footer .footer-inner.f-about .copyright-text {
  margin-top: 20px;
}
.page-three .footer .copyright-area .inner-content {
  margin-top: 50px;
}
.page-three .footer .copyright-area .copyright-owner {
  margin-top: 3px;
}

.light-blue {
  background-color: #78a7e0;
}

/* Add to your stylesheet */
.rdt_TableWrapper {
    margin-bottom: 16px !important;
}

.rdt_TableBody {
    margin-bottom: 16px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-three .section-title {
    padding: 0px 200px;
    margin-bottom: 70px;
  }
  .page-three .navbar-nav .nav-item a.dd-menu::after {
    right: 13px;
  }
  .page-three .hero-area .hero-content h1 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .page-three .feature .upper-content {
    padding-right: 100px;
  }
  .page-three .footer .footer-inner.newsletter {
    padding-left: 80px;
  }
}
@media only screen and (min-width: 1400px) {
  .page-three .feature .upper-content {
    padding-right: 100px;
  }
  .page-three .footer .footer-inner.newsletter {
    padding-left: 80px;
  }
}

.page-three .team .img-effect2 {
  overflow: hidden; /* Back to hidden */
  border-radius: 8px;
}

.page-three .team .img-effect2 .team-block {
  position: relative;
  width: 350px;
  height: auto;
  overflow: visible;
  border-radius: 8px;
}

.page-three .team .img-effect2 .team-block .team-img {
  position: relative;
  z-index: 2;
  width: 350px;
  height: auto;
  border-radius: 8px;
}

.page-three .team .img-effect2 .team-block .team-content {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 350px;
  padding: 25px 20px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1;
}

.page-three .team .img-effect2 .team-block:hover .team-content {
  bottom: 0px; /* Changed from -20px to 0px */
}

@media (min-width: 992px) { /* Adjust the breakpoint as needed */
  .hero-content {
    margin-bottom: 30px; /* Add 30px margin for wide screens */
  }
}
